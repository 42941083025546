/*
 *
 * This is the entry file for the application, only setup and boilerplate code.
 *
 */
import './wdyr';
import { browserHistory } from 'react-router';
import { ensureEnvVariablesWereWellReplaced } from '@dbh/environment';
import Sentry from '@dbh/sentry';
import {
  fnsLocaleDataLoaded,
  getDateFnsLocaleData,
} from '@dbh/date-fns-locales-redux';
import { appLocales, appCountries } from '@dbh/i18n';
import { getSerializedDataFromDom } from '@dbh/dom';
import { APP_STATE_DOM_ID } from '@dbh/dom-ids';
import {
  makeSelectCountryAndLocale,
  syncHistoryWithStore,
} from '@dbh/routing-redux';
import { makeSelectRedirectTo } from '@dbh/webpages-redux';
// eslint-disable-next-line import/extensions
import '!file-loader?name=[name].[ext]!../images/favicon.ico';
// eslint-disable-next-line import/extensions
import '!file-loader?name=[name].[ext]!../images/safari-pinned-tab.svg';
// Import all the third party stuff.
import { initializeSentryBrowserAndGetMiddleware } from '@dbh/sentry-extra';
import configureStore from '@dbh/configure-redux-store';
import renderInBrowser from '@dbh/render-in-browser';
import createRoutes from '@dbh/routes';
import AppRoot from '@dbh/app-root';
import { redirectToDefaultHomePage, adaptHydratedState } from './helpers';

const initApp = () => {
  ensureEnvVariablesWereWellReplaced();

  const sentryReduxEnhancer = initializeSentryBrowserAndGetMiddleware();

  let initialState;
  try {
    initialState = getSerializedDataFromDom(APP_STATE_DOM_ID);
  } catch (AppStateHydrationError) {
    Sentry.captureException(AppStateHydrationError);

    throw AppStateHydrationError;
  }

  // @TODO: Remove this once we have a better way to hydrate the state.
  // Or we completely remove `immutableJS` from the project.
  initialState = adaptHydratedState(initialState);

  // Create `redux` store with history,
  // This uses the singleton `browserHistory` provided by `react-router`.
  // Optionally, this could be changed to leverage a created history
  // e.g. `const browserHistory = useRouterHistory(createBrowserHistory)();`.
  const store = configureStore(initialState, browserHistory, {
    sentryReduxEnhancer,
    sagaErrorHandler: (err) => {
      // Sagas will swallow exceptions, that's why we capture them
      // in the `sagaErrorHandler`.
      Sentry.captureException(err);

      // In case the "re-throw" does not work for some reason.
      // eslint-disable-next-line no-console
      console.error(err);

      // "Re-throw" the error in order to (hopefully) show it up in the browser
      // console too.
      throw err;
    },
  });

  const routes = createRoutes(store);

  const history = syncHistoryWithStore(browserHistory, store);

  const state = store.getState();

  const redirectTo = makeSelectRedirectTo()(state);

  const render = () => {
    try {
      renderInBrowser({ store, redirectTo, routes, history, AppRoot });
    } catch (error) {
      // This might or might not be necessary, in the worst case it's a noop so
      // let's leave it.
      Sentry.captureException(error);
      // Re-throw the error in order to show it up in the browser console too.
      throw error;
    }
  };

  // @example `it-IT`.
  const { country, locale } = makeSelectCountryAndLocale()(state).toObject();

  const isSupportedCountryAndLocaleCombination =
    appCountries.includes(country) && appLocales.includes(locale);

  if (isSupportedCountryAndLocaleCombination) {
    getDateFnsLocaleData(locale)
      .then((dateFnsLocale) => {
        // `date-fns` locales data contain functions which can't be serialized,
        // and will be missing in the store; therefore we update the `redux`
        // store with the correct Object, once, on the landing page.
        store.dispatch(
          fnsLocaleDataLoaded({
            country,
            locale,
            dateFnsLocale,
          }),
        );
      })
      .then(render);
  } else {
    // Both the country and locale are missing in the `URL`. We have `express`
    // middlewares that handle this, so this is unlikely to happen, unless
    // the user taps a link with missing country or locale in the browser,
    // after having loaded the app.
    // @example @see {@link https://www.daybreakhotels.com/nocountrynorlocale}.
    redirectToDefaultHomePage();
  }
};

initApp();
